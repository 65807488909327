exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-acc-settings-tsx": () => import("./../../../src/pages/acc-settings.tsx" /* webpackChunkName: "component---src-pages-acc-settings-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-log-in-tsx": () => import("./../../../src/pages/log-in.tsx" /* webpackChunkName: "component---src-pages-log-in-tsx" */),
  "component---src-pages-password-reset-tsx": () => import("./../../../src/pages/password-reset.tsx" /* webpackChunkName: "component---src-pages-password-reset-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-set-password-tsx": () => import("./../../../src/pages/set-password.tsx" /* webpackChunkName: "component---src-pages-set-password-tsx" */),
  "component---src-pages-shipping-policy-tsx": () => import("./../../../src/pages/shipping-policy.tsx" /* webpackChunkName: "component---src-pages-shipping-policy-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-ty-for-registration-tsx": () => import("./../../../src/pages/ty-for-registration.tsx" /* webpackChunkName: "component---src-pages-ty-for-registration-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

